.checkIn {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 10px;

    text-align: center;
}

.infoText {
    font-size: larger;
    font-weight: 600;
}

.timeBox {
    font-size: x-large;
}