.errorMsg {
    color: var(--negative);
    font-size: small;
    text-align: center;
    
    margin-bottom: 10px;
}

.buttonText {
    font-size: medium;
}

.loaderText {
    font-size: large;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 4px solid transparent;
        border-top-color: #ffffff;
        border-radius: 50%;
        animation: button-loading-spinner 1s ease infinite;
    }
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}