@import "~@o4c/css/src/mixins/elevations";

.errorsContainer {
   background-color: rgba(0, 0, 0, 0.2);
   position: absolute;
   top: 0;
   left: 0;
   padding: 24px 16px;
   display: flex;
   height: 100vh;
   width: 100%;
   box-sizing: border-box;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   
}

.errorDetails {
   margin-top: 24px;
   padding: 16px;
   background: var(--grey-lighter);
   border-radius: var(--card-border-radius);
}
