.container {
    width: 100vw;
    display: flex;
    justify-content: center;
    background-color: #bbb;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.wrapper {
    min-width: 350px;
    width: 70vw !important;
    width: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    padding:  16px 8px;
    gap: 8px;
    box-sizing: border-box;
    padding-inline: auto;
    margin-bottom: 16px;

    button {
        background-image: linear-gradient(#f7f8fa ,#e7e9ec);
        border-width: 0;
        border-radius: 4px;
        box-sizing: border-box;
        color: #0f1111;
        cursor: pointer;
        display: inline-block;
        font-size: 24px;
        font-weight: 900;
        outline: 0;
        padding: 0 8px;
        overflow: hidden;
        text-align: center;
    }
}
