// Load CSS Framework Foundation and Icon font
@import "~@o4c/css/src/foundation";
@import "~@o4c/css/src/core/fonts";
@import "~@o4c/css/src/core/typography";

// Load CSS Framework Components
@import "~@o4c/css/src/components/timelines";
@import "~@o4c/css/src/components/cards";
@import "~@o4c/css/src/components/buttons";

// Load CSS Framwork Mixins
@import "~@o4c/css/src/mixins/elevations";

@import "~@o4c/css/src/components/text-fields";

* {
    // Prevent blue touch callout backgrounds
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

textarea,
input {
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

html {
    scroll-behavior: smooth;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--grey-lighter);
    color: var(--dark);
    margin: 0;
    padding: 0;
}

.o4c.button.outline {
    @include elevation1();
}

.o4c.card {
    @include elevation3();
    .header i {
        color: var(--negative);
    }
    .main p {
        margin-top: 0;
    }
}
