body {
  display: flex;
  flex-direction: column;
  font-family: "Roboto";
  font-size: 12px;
  background-color: var(--secondary);
  height: 100vh;
  overflow-x: hidden;
  margin: 0 auto;
}

.foreground {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  .timeAndDateContainter {
      display: flex;
      flex: 0;

      .timeLabel {
        color: white;
    
        height: fit-content;
        margin: auto;
        padding: 40px 0;
        text-align: center;
        
        .date {
            font-size: small;
            text-transform: uppercase;
            padding-bottom: 10px;
        }
        
        .time {
            font-size: xx-large;
            font-weight: 600;
        }
    }
  }

  .mainBoxWrapper {
    padding: 16px;
    display: flex;
    justify-content: center;
  }
  
  .helpNoteContainer {
      display: flex;
      flex: 1;
      align-content: flex-start;
      flex-wrap: wrap;
      background-color: var(--white);
      height: 100%;

      .helpMeNote {
        color: var(--grey-dark);
        font-size: small;
        margin: auto;

        padding: 40px 0;
        text-align: center;
    }
  }
  &:has(.scroll) {
    height: calc(100vh + 240px);
  }
}