.registerBox {
    max-width: 420px;
    width: 100%;

    display: flex;
    flex-direction: column;
    overflow: hidden;


    background-color: var(--grey-lighter);
    border-radius: 8px;
    box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.2);

}

.title {
    background-color: white;
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 16px 8px;

    background-color: white;
    border-bottom: 1px solid var(--grey-light);


}

.titleIcon {
    padding-left: 10px;
    margin: auto;
}

.titleText {
    flex: 2;
    font-size: medium;
    font-weight: 600;
    padding-right: 10px;
    margin: auto 0;
}

.content {
    background-color: var(--grey-lighter);

}

.textFields {
    background-color: var(--grey-lighter);
    margin: 24px;

    display: flex;
    flex-direction: column;
    gap: 16px;
}

.footer {
    display: flex;
    gap: 16px;
    width: 100%;

    background-color: white;
    border-top: 1px solid var(--grey-light);
}

.boxButton {
    width: inherit;
    margin: 20px 10px;

}
